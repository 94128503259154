
import { defineComponent, ref, onMounted } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { ApiLogisticsServiceProvider, ApilogisticsDoc } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { modalShowListener } from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import JwtService from "@/core/services/JwtService";
import { ElUpload } from "element-plus";
import CryptoJS from "crypto-js";

import PermissionCommon from "@/components/layout/PermissionCommon.vue";

declare type UploadStatus = "ready" | "uploading" | "success" | "fail";
interface ElFile extends File {
  uid: number;
}
interface File {
  id: number;
  name: string;
  percentage?: number;
  status: UploadStatus;
  size: number;
  response?: unknown;
  uid: number;
  url?: string;
  raw: ElFile;
}

interface TaggingItem {
  label: string;
  value: string;
}

interface fileItem {
  id: number;
  name: string;
}

export default defineComponent({
  name: "double-sale-order-shipment-label-upload-pdf",
  components: {
    PermissionCommon,
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const route = useRoute();

    const formRef = ref<null | HTMLFormElement>(null);
    const UploadPDFRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      logistics_documents_id: 0,
      channel: "",
      service_provider: "",
      file: [] as fileItem[],
    });

    /*
     *必填项验证
     */
    const rules = ref({
      channel: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      service_provider: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      file: [
        {
          required: true,
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (value.length === 0) {
                reject(t("common.isRequired"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
    });

    /*
     *下拉框配置
     */
    const options = ref({
      service_provider: [] as TaggingItem[],
    });

    /*
     *下拉选择物流商
     */
    const getLogisticsServiceProviderData = async () => {
      const { data } =
        await ApiLogisticsServiceProvider.getLogisticsServiceProviderData({
          max_item: "all",
        });
      if (data.code == 0) {
        let shipmentProviderOptionsData = data.data;
        let pickUp = {
          label: t("shipments.pickUpBy"),
          value: -1,
        };
        shipmentProviderOptionsData.push(pickUp);
        options.value.service_provider = shipmentProviderOptionsData;
      }
    };

    /*
     *上传
     */
    const fileList = ref<Array<File>>([]);
    const uploadHeaders = ref({
      Authorization: `Bearer ${JwtService.getToken()}`,
    });
    const uploadList = ref<Array<any>>([]);
    const maxFiles = ref(20);
    const uploadRef = ref<InstanceType<typeof ElUpload>>();
    const uploadLoading = ref<boolean>(false);

    const onUploadChange = async (file, files) => {
      uploadList.value.push(file);
      const params = new FormData();
      let fileArr: any[] = [];
      uploadList.value.map((item) => {
        if (item.raw) {
          fileArr.push(item);
          params.append("files[]", item.raw, item.raw.name);
        }
      });
      params.append("storage", "logistics-documents-aliyun");
      debounceUpload(params, fileArr);
    };

    const _fileMd5Sum = (file) => {
      return new Promise((resolve) => {
        const fileReader = new FileReader();
        fileReader.readAsBinaryString(file);
        fileReader.onload = (ev) => {
          if (ev.target) {
            const md5 = CryptoJS.MD5(
              CryptoJS.enc.Latin1.parse(ev.target.result as string)
            ).toString(CryptoJS.enc.Hex);
            resolve(md5);
          }
        };
      });
    };

    const uploadPDF = async (params, files: any[]) => {
      uploadLoading.value = true;
      for (let index = 0; index < files.length; index++) {
        fileList.value.push(files[index]);
      }
      // 分别上传每个文件
      for (let index = 0; index < files.length; index++) {
        // 快速预传
        const QThash = (await _fileMd5Sum(files[index].raw)) as string;
        const QTname = files[index].name as string;
        const { data: quickTransferData } = await ApilogisticsDoc.quickTransfer(
          {
            storage: params.get("storage"),
            files: [{ hash: QThash, name: QTname }],
          }
        );
        if (
          quickTransferData &&
          quickTransferData.code === 0 &&
          quickTransferData.data.length > 0
        ) {
          files[index].status = "success";
          // 如果有id，直接取
          formData.value.file.push({
            id: quickTransferData.data[0].id,
            name: quickTransferData.data[0].name.replace(/\.[^/.]+$/, ""),
          });
          uploadList.value = [];
        } else {
          // 如果没有id，使用普通文件上传接口，然后取
          const { data: putFilesData } = await ApilogisticsDoc.putFiles(params);
          if (putFilesData.code === 0) {
            files[index].status = "success";
            formData.value.file.push({
              id: putFilesData.data[0].id,
              name: putFilesData.data[0].name.replace(/\.[^/.]+$/, ""),
            });
            uploadList.value = [];
          } else {
            files[index].status = "fail";
            // showServerErrorMsg(putFilesData);
            uploadList.value = [];
            uploadRef.value?.handleRemove(files[index], files[index].raw);
          }
        }
      }
      uploadLoading.value = false;
    };
    const debounceUpload = _.debounce(uploadPDF, 100);

    const handleRemove = (file) => {
      fileList.value.forEach((item, index) => {
        if (item.uid == file.uid) {
          fileList.value.splice(index, 1);
          uploadList.value.splice(index, 1);
          formData.value.file.splice(index, 1);
        }
      });
    };

    /*
     *提交
     */
    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApilogisticsDoc.createItem(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                updateList();
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    /*
     *关闭弹框
     */
    const resetForm = () => {
      formRef.value?.resetFields();
      formData.value = {
        logistics_documents_id: 0,
        channel: "",
        service_provider: "",
        file: [] as fileItem[],
      };
      fileList.value.splice(0, fileList.value.length);
    };
    const handleDiscard = () => {
      hideModal(UploadPDFRef.value);
    };
    const updateList = () => {
      handleDiscard();
      emit("update-list");
    };
    onMounted(() => {
      modalShowListener(UploadPDFRef.value, () => {
        resetForm();
        formData.value.logistics_documents_id = Number(
          route.params.id as string
        );
        getLogisticsServiceProviderData();
      });
      modalHideListener(UploadPDFRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      props,
      loading,
      uploadLoading,
      formData,
      rules,
      formRef,
      UploadPDFRef,
      submitButton,
      options,
      submit,
      resetForm,
      handleDiscard,
      fileList,
      uploadHeaders,
      onUploadChange,
      handleRemove,
      maxFiles,
    };
  },
});

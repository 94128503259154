
import { defineComponent, onMounted, onActivated, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { ApiBase, ApilogisticsDoc } from "@/core/api";
import _ from "lodash";
import mixin from "@/mixins";
import { Mutations } from "@/store/enums/StoreEnums";
import store from "@/store";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  formatDate,
  formatDateTime,
  commonExportFile,
} from "@/core/directive/function/common";

import PermissionCommon from "@/components/layout/PermissionCommon.vue";
import MBDatatableWithDisabled from "./MBDatatableWithDisabled.vue";
import ShipmentLabelPreview from "./ShipmentLabelPreview.vue";
import UploadShipmentLabel from "./UploadShipmentLabel.vue";

interface SortOption {
  field: string;
  direction: string;
}

interface FilterOption {
  field: string;
  value: string;
  from?: string;
  to?: number;
  condition?: string;
}

interface LogisticsDocItemListTableData {
  channel: string;
  created_at: string;
  id: number;
  file_id: number;
  file_path: string;
  service_provider: number;
  status: number;
  stock_time: string;
  tracking_no: string;
  __show: {
    service_provider: string;
    status: string;
  };
}

interface imagePreviewParams {
  logistics_document_id: number;
  file_path_name: string;
  logistics_document_status: number;
}

export interface TaggingItem {
  label: string;
  value: number | string;
}

export default defineComponent({
  name: "double-sale-order-shipment-label-list",
  components: {
    MBDatatableWithDisabled,
    ShipmentLabelPreview,
    UploadShipmentLabel,
    PermissionCommon,
  },
  setup(props, context) {
    const { t } = useI18n();
    const route = useRoute();
    const { showServerErrorMsg } = mixin();

    const loading = ref(true);
    const tableData = ref<Array<LogisticsDocItemListTableData>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref("");
    const sortOptions = ref<Array<any>>([
      {
        field: "created_at",
        direction: "desc",
      },
    ]);
    const filterOptions = ref<Array<any>>([]);
    const tableHeader = ref([
      {
        name: t("shipmentLabel.shipmentLabelNo"),
        key: "tracking_no",
        sortable: false,
      },
      {
        name: t("shipmentLabel.channel"),
        key: "channel",
        sortable: false,
      },
      {
        name: t("shipmentLabel.logisticsCompany"),
        key: "service_provider",
        sortable: false,
      },
      {
        name: t("shipmentLabel.createDate"),
        key: "created_at",
        sortable: false,
      },
      {
        name: t("shipmentLabel.stockOutDate"),
        key: "stock_time",
        sortable: false,
      },
      {
        name: t("shipmentLabel.status"),
        key: "status",
        sortable: false,
        align: "right",
      },
      {
        name: t("shipmentLabel.action"),
        key: "action",
        sortable: false,
        align: "right",
      },
    ]);

    /*
     *拉取数据
     */
    const getLogisticsDocItemList = (
      page: number,
      pageSize: number,
      search?: string,
      sortOptions?: Array<SortOption>,
      filterOptions?: Array<FilterOption>
    ) => {
      loading.value = true;
      ApilogisticsDoc.getLogisticsDocItemList({
        id: route.params.id as string,
        page: page,
        page_size: pageSize,
        search_value: search,
        sort_orders: sortOptions,
        filter_group: filterOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            // 传回简介数据
            context.emit("getFormData", data.data.logistic_document);
            // 页面显示数据赋值
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
            // 初始化：bootstrap菜单按钮
            setTimeout(() => {
              MenuComponent.reinitialization();
            }, 1000);
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const updateList = () => {
      getLogisticsDocItemList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    /*
     *获取Tagging
     */
    const options = ref({
      logisticsDocumentsItemsStatus: [] as TaggingItem[],
    });
    const getTaggingData = () => {
      ApiBase.getTaggingData({
        short_key: ["logistics_documents_items_status"],
      })
        .then(({ data }) => {
          if (data.code == 0) {
            options.value.logisticsDocumentsItemsStatus =
              data.data.logistics_documents_items_status.items;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    /*
     *多选功能
     */
    const checkedFileIds = ref<Array<number>>([]);
    const checkedIds = ref<Array<number>>([]);
    const onRowsSelectChange = (selection) => {
      let Ids: number[] = [];
      let file_ids: number[] = [];
      selection.forEach((item) => {
        Ids.push(item.id);
        file_ids.push(item.file_id);
      });
      checkedIds.value = Ids;
      checkedFileIds.value = file_ids;
    };
    // 禁止选中的条件函数（根据raw的数据判断，返回true则禁止）
    const selectionDisabledCondition = (raw: any) => {
      if (raw.status == 90) {
        return true;
      }
    };

    /*
     *分页功能
     */
    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getLogisticsDocItemList(
        page,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };
    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      updateList();
      store.commit(Mutations.SET_CURRENT_PAGE_CONFIG, {
        key: route.name,
        data: {
          pageSize: pageSize.value,
        },
      });
    };

    /*
     *批量下载ZIP
     */
    const downloadButton = ref<HTMLElement | null>(null);
    const downloadButtonLoading = ref(false);
    const downloadZip = () => {
      downloadButtonLoading.value = true;
      if (downloadButton.value) {
        downloadButton.value.setAttribute("data-kt-indicator", "on");
      }
      ApilogisticsDoc.downloadZip({ id: checkedFileIds.value })
        .then((data) => {
          downloadButtonLoading.value = false;
          downloadButton.value?.removeAttribute("data-kt-indicator");
          commonExportFile(data, null);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    /*
     *单独下载PDF
     */
    const actionButtonLoading = (id: number) => {
      const actionButton = document.getElementById(`action-button-${id}`);
      if (actionButton) {
        actionButton.setAttribute("data-kt-indicator", "on");
        actionButton.setAttribute("disabled", "disabled");
      }
    };
    const actionButtonRemoveLoading = (id: number) => {
      const actionButton = document.getElementById(`action-button-${id}`);
      if (actionButton) {
        actionButton.removeAttribute("data-kt-indicator");
        actionButton.removeAttribute("disabled");
      }
    };
    const downloadPdf = (id: number, file_id: number) => {
      actionButtonLoading(id);
      ApilogisticsDoc.downloadZip({ id: [file_id] })
        .then((data) => {
          actionButtonRemoveLoading(id);
          commonExportFile(data, null);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    /*
     *面单作废
     */
    const repealItem = (id: number) => {
      actionButtonLoading(id);
      Swal.fire({
        text: t("shipmentLabel.voidLabelTip"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true;
          ApilogisticsDoc.updateItemStatus({
            id: id,
            status: 90,
          })
            .then(({ data }) => {
              actionButtonRemoveLoading(id);
              loading.value = false;
              if (data.code == 0) {
                getLogisticsDocItemList(
                  currentPage.value,
                  pageSize.value,
                  search.value,
                  sortOptions.value,
                  filterOptions.value
                );
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          actionButtonRemoveLoading(id);
        }
      });
    };

    /*
     *面单图片预览
     */
    const imagePreviewParams = ref<imagePreviewParams>({
      logistics_document_id: 0,
      file_path_name: "",
      logistics_document_status: 0,
    });
    const imagePreviewDataChange = (item: LogisticsDocItemListTableData) => {
      imagePreviewParams.value.logistics_document_id = item.id;
      imagePreviewParams.value.file_path_name = item.file_path;
      imagePreviewParams.value.logistics_document_status = item.status;
    };

    const getFromInfo = () => {
      Promise.all([
        getTaggingData(),
        getLogisticsDocItemList(
          currentPage.value,
          pageSize.value,
          search.value,
          sortOptions.value,
          filterOptions.value
        ),
      ])
        .then(() => {
          return;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      getFromInfo();
    });
    onActivated(() => {
      MenuComponent.reinitialization();
      getFromInfo();
    });

    return {
      t,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      onCurrentPageChange,
      onRowsPerPageChange,
      formatDate,
      formatDateTime,
      onRowsSelectChange,
      checkedFileIds,
      downloadButton,
      downloadButtonLoading,
      downloadZip,
      downloadPdf,
      updateList,
      repealItem,
      selectionDisabledCondition,
      imagePreviewParams,
      imagePreviewDataChange,
      options,
    };
  },
});

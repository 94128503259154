
import { defineComponent, onMounted, ref } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { modalShowListener } from "@/core/directive/function/common";
import { ApilogisticsDoc } from "@/core/api";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";

interface searchParams {
  logistics_document_id: number;
  file_path_name: string;
  logistics_document_status: number;
}

export default defineComponent({
  name: "double-sale-order-shipment-label-image-preview",
  props: {
    searchParams: {
      type: Object as () => searchParams,
      default: () => {
        return {
          logistics_document_id: 0,
          file_path_name: "",
          logistics_document_status: 0,
        };
      },
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const loading = ref<boolean>(true);
    const LogisticsDocImagePreviewRef = ref<null | HTMLElement>(null);
    const { showServerErrorMsg } = mixin();

    /*
     *拉取数据
     */
    var timesTamp = ref(0);
    const base64Image = ref<string>();
    const getLogisticsDocImagePreview = async () => {
      loading.value = true;
      // 防止相近的两次请求结果覆盖
      timesTamp.value = new Date().getTime();
      const _thisTime = new Date().getTime();

      const { data } = await ApilogisticsDoc.getLogisticsDocImagePreview({
        name: props.searchParams.file_path_name,
      });
      if (_thisTime == timesTamp.value) {
        loading.value = false;
        var reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = function () {
          base64Image.value = this.result as string;
        };
      } else {
        return;
      }
    };

    /*
     *面单放行
     */
    const approveButton = ref<HTMLElement | null>(null);
    const approveShipmentLabel = () => {
      if (approveButton.value) {
        approveButton.value.setAttribute("data-kt-indicator", "on");
      }
      Swal.fire({
        text: t("shipmentLabel.approveLabelTip"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true;
          ApilogisticsDoc.updateItemStatus({
            id: props.searchParams.logistics_document_id,
            status: 10,
          })
            .then(({ data }) => {
              approveButton.value?.removeAttribute("data-kt-indicator");
              loading.value = false;
              if (data.code == 0) {
                updateList();
                Swal.fire({
                  text: t("shipmentLabel.approveSuccessful"),
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: t("common.okGotIt"),
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  resetForm();
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          approveButton.value?.removeAttribute("data-kt-indicator");
        }
      });
    };

    /*
     *关闭弹框
     */
    const resetForm = () => {
      base64Image.value = "";
      loading.value = true;
      ApilogisticsDoc.cancelLogisticsDocImagePreview();
    };
    const handleDiscard = () => {
      hideModal(LogisticsDocImagePreviewRef.value);
    };
    const updateList = () => {
      handleDiscard();
      emit("update-list");
    };
    onMounted(() => {
      modalShowListener(LogisticsDocImagePreviewRef.value, () => {
        setTimeout(() => {
          getLogisticsDocImagePreview();
        });
      });
      modalHideListener(LogisticsDocImagePreviewRef.value, () => {
        setTimeout(() => {
          resetForm();
        });
      });
    });
    return {
      t,
      loading,
      LogisticsDocImagePreviewRef,
      base64Image,
      approveButton,
      approveShipmentLabel,
    };
  },
});
